import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  getDate,
  getDateSelected,
  getTime,
  updateDate,
  updateDateSelected,
  updateTime,
  updateSelectedAppointmentId,
  deleteDateSelected,
  deleteTime,
} from '../../features/checkout/checkoutSlice'
import {
  dateExistsInAppointments,
  getNewMonthViewByDate,
  getOptionsTime,
  nextAvailableDate,
  tileDisabled,
  newDate,
  isoStringToHumanReadable,
  yesterdayDate,
} from './utils'

const CalendarCheckout = ({ appointments }) => {
  const dispatch = useDispatch()
  const localDate = useSelector(getDate)
  const dateSelected = useSelector(getDateSelected)
  const [value, setValue] = useState(null)
  const time = useSelector(getTime)

  useEffect(() => {
    if (!localDate) {
      const nextDate = nextAvailableDate(appointments)
      setValue(newDate(nextDate))
      dispatch(updateDate(nextDate))
    } else if (dateExistsInAppointments(appointments, localDate)) {
      setValue(newDate(localDate))
      dispatch(updateDate(localDate))
    } else {
      const nextDate = nextAvailableDate(appointments)
      setValue(newDate(nextDate))
      dispatch(updateDate(nextDate))
    }
  }, [appointments, dispatch, localDate])

  const handleOnClickDay = (value) => {
    const stringDate = value.toISOString()
    dispatch(updateDateSelected(stringDate))
    setValue(value)
  }

  const handleTimeSelect = (e) => {
    const newTime = e.label
    dispatch(updateTime(newTime))
    dispatch(updateSelectedAppointmentId(e.value))
  }

  const changeViewMonth = (view, action) => {
    if (action !== 'onChange') {
      const date = getNewMonthViewByDate(
        appointments,
        newDate(localDate),
        view,
        action
      )
      setValue(newDate(date))
      dispatch(updateDate(date))
      dispatch(deleteDateSelected())
      dispatch(deleteTime())
    }
  }

  const handleOnActiveStartDateChange = ({ action, view }) =>
    changeViewMonth(view, action)

  // iso string to human readable date and time
  const dateTimeSelected = isoStringToHumanReadable(dateSelected, time)

  return (
    <div className='wizard-body'>
      <div className='step initial active'>
        <h4 className='calendar-title'>
          Seleccioná la fecha y hora para tu turno: {dateTimeSelected}
        </h4>
        <div className='calendar-time-picker-container'>
          <div className='calendar-picker-container'>
            <Calendar
              locale='es'
              className=''
              view='month'
              minDetail='month'
              showNeighboringMonth={false}
              tileDisabled={({ date, view }) =>
                tileDisabled(appointments, { date, view })
              }
              onClickDay={handleOnClickDay}
              minDate={yesterdayDate()}
              onActiveStartDateChange={handleOnActiveStartDateChange}
              activeStartDate={value}
            />
          </div>
          <div className='calendar-time-container'>
            <div className='time-container'>
              <Select
                className='react-select-containerC'
                classNamePrefix='react-selectC'
                isSearchable={false}
                placeholder='Select a time'
                options={
                  dateSelected ? getOptionsTime(appointments, dateSelected) : []
                }
                menuIsOpen
                onChange={handleTimeSelect}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarCheckout
