export const messages = {
  productAddedToCart: 'Producto agregado al carrito',
  productRemovedFromCart: 'Producto eliminado del carrito',
  stockLimitReached: 'Límite de stock alcanzado',
  cartAlreadyHasCalendarProduct:
    'El carrito ya contiene el máximo de productos de esta categoría',
  cartProductQuantityUpdated: 'Cantidad de productos actualizada',
  appoitmentMissing: 'Por favor, seleccione un turno',
  paymentMethodMissing: 'Por favor, seleccione un método de pago',
  submitFormConfirmation: 'Mensaje enviado',
  genericErrorMessage: 'Ups, ha habido un error',
}
