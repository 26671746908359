export const accordionData = [
  {
    id: '1',
    title: '¿Las consultas son online?',
    content: `<p>Sí, la dinámica es exactamente la misma que si estaríamos en el consultorio presencial.<br><br>¡En este tiempo nos tocó adaptarnos a la modalidad online y he visto resultados increíbles que ni yo hubiera imaginado, por eso sigo apostando a esta variante!<br><br>Como digo siempre, cada uno de ustedes recibe las herramientas y mi acompañamiento en todo momento para el logro de sus objetivos, pero siempre debe ser un trabajo en equipo y el paciente debe comprometerse al 100%.</p>`,
  },
  {
    id: '2',
    title: '¿Es necesario ser mayor de edad?',
    content: `<p >Sí, para acceder a mis servicios es requisito ser mayor de edad.</p>`,
  },
  {
    id: '3',
    title: '¿Para qué sirve el tratamiento online?',
    content: ` <p class="bold">Te puedo ayudar a:.</p>
    <ul>
      <li>Disminuir grasa y peso corporal</li>
        <li>Mejorar hábitos alimentarios</li>
        <li>Aumentar masa muscular</li>
        <li>Mejorar el rendimiento deportivo</li>
        <li>Mejorar el perfil lipídico (grasas)</li>
        <li>Aumentar los niveles de hierro</li>
        <li>Planificar una alimentación vegetariana.</li>
        <li>Mejorar glucemias</li>
        <li>Mejorar la calidad de nutrientes en embarazo</li>
        <li>Otras.</li>
    </ul>`,
  },
  {
    id: '4',
    title: '¿Cómo se miden los cambios físicos?',
    content: `<p class="bold">Esta es la única limitante en esta modalidad, pero implementaremos otras herramientas para conocer referencias de la composición corporal del paciente e ir viendo progresos a lo largo del tratamiento. Utilizaremos otras excelentes opciones: como la fotografía de frente y perfil antes de cada consulta (malla, ropa interior o ropa deportiva). También les proporcionaré una planilla para que se realicen algunas mediciones (medidas de cintura, cadera, muslo, etc.). En caso de que el paciente cuente con balanza, podrá pesarse y obtener un control extra.</p>`,
  },
  {
    id: '5',
    title: '¿Qué es el plan general?',
    content: `<p class="bold">El plan general es una guía que cuenta con una breve descripción sobre la importancia de cada nutriente en cada una de nuestras comidas, en qué alimentos encontramos cada nutriente. Como armar platos saludables para que sean armónicos y equilibrados. Ejemplos de desayunos, almuerzos, meriendas, cenas e ideas de menú. Esta orientado para aquellas personas que quieran encaminarse hacia un estilo de vida saludable. Es la mejor transición. <br> <br>
              <b>IMPORTANTE:</b> el plan no incluye cantidades ni porciones, ni seguimiento nutricional. El pago se realiza por única vez y podrás tener el pdf para acceder en el momento que lo desees.</p>`,
  },
  {
    id: '6',
    title: '¿Qué es el plan personalizado?',
    content: `<p class="bold">El plan personalizado se ajusta a los gustos, horarios, actividad, patología de cada paciente. Se realiza el cálculo de las porciones que el paciente necesite de acuerdo al objetivo que tenga. También incluye recomendaciones, selección de alimentos y sugerencias de menú. Está orientado para aquellas personas que quieran un plan ajustado a sus necesidades específicas y continuar trabajando en sus hábitos alimentarios.</p>`,
  },
  {
    id: '7',
    title: '¿Para qué sirve el control nutricional?',
    content: `<p class="bold">El control nutricional sirve para seguir trabajando e incorporando nuevas herramientas, siempre de forma <b>personalizada</b>, despejar dudas, trabajar errores que hayan surgido de la consulta previa o en el transcurso de los días, modificar porciones (de ser necesario), crear nuevas ideas de menú y brindar apoyo en cada momento.<br><br>
    <b>Importante:</b> las consultas por WhatsApp, Instagram u otras redes no serán contestadas, ya que el espacio apropiado y profesional para dichas dudas, es la consulta control previamente planificada.</p>`,
  },
  {
    id: '8',
    title: '¿Qué se necesita para las consultas?',
    content: `<ul>
    <li>Dispositivo con buena conexión a internet</li>
    <li>Puntualidad en las consultas y entrega de registros solicitados en cada una de ellas para poder trabajar de manera ordenada</li>
    <li>Perseverancia y paciencia</li>
  </ul>`,
  },
];
